import { action, observable } from "mobx";

import { IActionControllerConfig, ActionType } from './types'

function isUserAction(type: ActionType) {
    return [ActionType.IMMEDIATE_ACTION, ActionType.USER_ACTION].includes(type);
}

export class ActionController implements IActionControllerConfig {

    currentAction = 0;

    @observable shouldPreventAction = false;

    //major actions
    @observable actionInProgress = false;

    actionQueueTimeout = null;

    //minor actions
    minorActionOnWait = false;
    minorActionTimeout = null;
    lastMinorActionTime: number | null = null;

    //overridable values
    functionCall = null;
    actionThrottle = 700;
    minorActionDelay = 4000;

    constructor(config: IActionControllerConfig) {
        Object.assign(this, config);

        if (typeof this.functionCall != 'function') {
            throw "Function call required";
        }
    }

    @action.bound
    stopActions(stopLastAction = true) {
        this.shouldPreventAction = true;
        stopLastAction && this.reset();
    }

    @action.bound
    startActions() {
        this.shouldPreventAction = false;
    }

    handleNewAction(callbackArguments: {
        type?: ActionType;
        actionId?: number;
    }) {
        if (this.shouldPreventAction) {
            return;
        }

        const { type } = callbackArguments;

        if (type === ActionType.IMMEDIATE_ACTION) {
            callbackArguments.actionId = this.newAction();
            this.forceMajorAction(callbackArguments);
            return;
        }

        if (type === ActionType.USER_ACTION) {
            callbackArguments.actionId = this.newAction();
            this.scheduleMajorAction(callbackArguments);
            return;
        }

        //considered a minor action
        callbackArguments.actionId = this.currentAction;
        this.scheduleMinorAction(callbackArguments);

    }

    public resolveAction(callbackArguments: {
        type?: ActionType;
        actionId?: number;
    }) {
        if (this.shouldPreventAction) {
            return false;
        }

        if (!this.isCurrentAction(callbackArguments.actionId)) {
            return false;
        }

        if (isUserAction(callbackArguments.type)) {
            this.setActionInProgress(false);
            // preventing immediate minor action hit
            this.setLastMinorActionTimestamp();
            return true;
        }

        if (this.actionInProgress) {
            return false;
        }

        this.setMinorActionOnWait(false);
        this.setLastMinorActionTimestamp();

        return true;
    }

    newAction() {
        this.setActionInProgress(true);

        return ++this.currentAction;
    }

    @action.bound
    setActionInProgress(value: boolean) {
        this.actionInProgress = value;
        if (value) {
            this.resetMinorAction();
        }
    }

    isCurrentAction(action) {
        return action == this.currentAction;
    }

    resolveCurrentAction() {

        this.setActionInProgress(false);

        // @ts-expect-error this doesn't exist on this type
        if (this.actionResolveCallback) {
            // @ts-expect-error this doesn't exist on this type
            this.actionResolveCallback();
        }
    }

    //#region Major methods

    forceMajorAction(callbackArguments) {
        clearTimeout(this.actionQueueTimeout);
        this.fireMajorAction(callbackArguments);
    }

    scheduleMajorAction(callbackArguments) {
        clearTimeout(this.actionQueueTimeout);

        setTimeout(() => {
            if (!this.isCurrentAction(callbackArguments.actionId)) {
                return;
            }
            this.fireMajorAction(callbackArguments);
        }, this.actionThrottle);
    }

    fireMajorAction(callbackArguments) {
        this.functionCall(callbackArguments);
    }

    resetMajorAction() {
        this.currentAction++;
        clearTimeout(this.actionQueueTimeout);
        this.setActionInProgress(false);
    }
    //#endregion

    //#region Minor methods

    scheduleMinorAction(callbackArguments) {

        const { actionId } = callbackArguments;

        if (this.minorActionOnWait || this.actionInProgress) {
            return;
        }

        if (!this.lastMinorActionTime) {
            this.fireMinorAction(callbackArguments);
            return;
        }

        const timeDiff = Date.now() - this.lastMinorActionTime;

        if (timeDiff > this.minorActionDelay) {
            this.fireMinorAction(callbackArguments);
            return;
        }

        this.minorActionTimeout = setTimeout(() => {
            clearTimeout(this.minorActionTimeout);
            if (this.actionInProgress || !this.isCurrentAction(actionId)) {
                return;
            }
            this.fireMinorAction(callbackArguments);
        }, this.minorActionDelay - timeDiff);

    }

    fireMinorAction(callbackArguments) {
        this.setLastMinorActionTimestamp();
        this.setMinorActionOnWait(false);
        this.functionCall(callbackArguments);
    }

    setLastMinorActionTimestamp(value?: number | null) {
        this.lastMinorActionTime = value || Date.now();
    }

    setMinorActionOnWait(value) {
        this.minorActionOnWait = value;
    }

    resetMinorAction() {
        this.setMinorActionOnWait(false);
        clearTimeout(this.minorActionTimeout);
    }

    //#endregion

    //Automatic reset functionality
    reset() {
        this.resetMajorAction();
        this.resetMinorAction();
    }

    //Manually trigger reset when needed
    forceReset() {
        this.startActions();
        this.reset();
    }

}